import React from "react";
import { useNavigate } from "react-router-dom";

const Screensaver = () => {
  const navigate = useNavigate();

  const newApplicationInit = () => {
    navigate("/new-application", { replace: true });
  };

  return (
    <div className="screensaver-container bg-custom-primary">
      <img
        src="../assets/images/kiosk_background_v4.png"
        alt="background"
        className="screensaver"
        onClick={newApplicationInit}
      />
      {/* <video className="screensaver" loop autoPlay muted onClick={newApplicationInit}>
                <source src="/videos/screensaver.mp4" type="video/mp4" />
            </video> */}
    </div>
  );
};

export default Screensaver;
